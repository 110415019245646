<template>
  <section class="">
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.8-2022.09.26 <i> (FED Bar) </i>
        </h3>
      </div>
      <div class="col-12">
        <p>Warning Mail(Email):</p>
        <ul>
          <li><span>Do not log warning when for specific user and date is missing info (#2259)</span></li>
        </ul>
        <br/>
        <p>Dashboard(SMI):</p>
        <ul>
          <li><span>Prop Reports - handle positive SMI in reports</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.7-2022.09.23 <i> (FED Bar) </i>
        </h3>
      </div>
      <div class="col-12">
        <p>Dashboard(Calendar):</p>
        <ul>
          <li><span>Fix issue with not shown green row for today in US PC locale</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.6-2022.09.19 <i> (Piano Bar) </i>
        </h3>
      </div>
      <div class="col-12">
        <p>Blotter:</p>
        <ul>
          <li><span>Add setting per user for blotter duty. By default for all user is disabled</span></li>
        </ul>
        <br/>
        <p>Calendar(Events):</p>
        <ul>
          <li><span>Add ability to upload for one month forward</span></li>
          <li><span>Dashboard events that are passed will not shown already</span></li>
        </ul>
        <br/>
        <p>Dashboard(SMI):</p>
        <ul>
          <li><span>Add column for position in SMI table</span></li>
        </ul>
        <br/>
        <p>Monthly Statement:</p>
        <ul>
          <li><span>Return original method for calc ending balance & HWM</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.5-2022.07.28 <i> (Beer Bar) </i>
        </h3>
      </div>
      <div class="col-12">
        <p>Monthly Statement:</p>
        <ul>
          <li><span>Fix download monthly report</span></li>
        </ul>
        <br/>
        <p>Library:</p>
        <ul>
          <li><span>Add more four column in company memo table</span></li>
          <li><span>When upload file for company memo the user can check the SPY 10% up, SPY 10% down, FED ease, FED hike</span></li>
          <li><span>Add multiple sort for company memo table</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.4-2022.07.27 <i> (Green Bar) </i>
        </h3>
      </div>
      <div class="col-12">
        <p>Monthly Statement:</p>
        <ul>
          <li><span>Remove row taxes</span></li>
          <li><span>Rename (cons/withdrawal) and consolidate with taxes value</span></li>
          <li><span>Rework monthly report as remove separate account and only keep consolidated accounts report </span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.3-2022.07.26 <i> (Green light) </i>
        </h3>
      </div>
      <div class="col-12">
        <ul>
          <li><span>Prop report file is parsed based on column name instead of index</span></li>
          <li><span>In monthly statement - rename row bonuses and fines to adjustments/fines</span></li>
          <li><span>In monthly statement - add ability to comment to adjustments/fines</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.2-2022.07.22 <i> (Midnight) </i>
        </h3>
      </div>
      <div class="col-12">
        <ul>
          <li><span>Monthly statement user screen with option to see generated statement + download file</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.4.1-2022.07.20 <i> (SunLight) </i>
        </h3>
      </div>
      <div class="col-12">
        <ul>
          <li><span>Monthly statement admin screen with option to upload file for generate users monthly statements</span></li>
        </ul>
      </div>
    </div>
    <div class="row wow fadeIn ml-1 mr-1 mb-1">
      <div class="col-12">
        <h3 class="border-light">
          Version - 1.3.5-2022.07.15 <i> (MoonLight) </i>
        </h3>
      </div>
      <div class="col-12">
        <ul>
          <li><span>Add first system versions page</span></li>
          <li><span>Mark with red color row with rate more than 10 in report adjustments</span></li>
          <li><span>Add calendar events in Dashboard. Manage by csv in menu "System Settings"</span></li>
          <!--<li><span>Process HTB File: If file does not exist the just postponed to be processed next day</span></li>-->
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageVersions',
  components: {
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
